/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import { Container, Typography, Button } from "@mui/material";
import { carnet } from "react-router-dom";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import imagenes from "layouts/welcome/images/imagenn.jpg";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useEffect, useState } from "react";
import fetchWrapper from "fetchWrapper";
import usePermission from "hooks/usePermission";
import MDAlert from "components/Alert";
import { getAllCharts } from "./services/welcomeService";
function Bienvenida() {
  // Verificar permisos
  const [isAlert, setIsAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");
  const canViewCharts = usePermission("Charts.all");

  // Definir estados
  const [statistics, setStatistics] = useState({
    query1: 0,
    query2: 0,
    query3: 0,
    query4: 0,
    query5: [],
    query6: [],
    query7: [],
    query8: 0,
  });
  const [lastUpdated, setLastUpdated] = useState("");

  const getDefaultIfEmpty = (value) => {
    if (value === null || value === undefined || (Array.isArray(value) && value.length === 0)) {
      return 0;
    }
    return value;
  };

  useEffect(() => {
    let isMounted = true; // Para evitar actualizar estado en componentes desmontados
    const fetchData = async () => {
      if (!canViewCharts) {
        return;
      }

      try {
        const result = await getAllCharts();

        // Verificar si el componente sigue montado
        if (!isMounted) return;

        if (result.status !== false) {
          const data = result.payload;
          // Actualizar el estado del gráfico con datos reales si tiene permisos
          setStatistics({
            query1: getDefaultIfEmpty(data?.query1),
            query2: getDefaultIfEmpty(data?.query2?.length),
            query3: getDefaultIfEmpty(data?.query3?.length),
            query4: getDefaultIfEmpty(data?.query4),
            query5: getDefaultIfEmpty(data?.query5),
            query6: getDefaultIfEmpty(data?.query6),
            query7: getDefaultIfEmpty(data?.query7),
            query8: getDefaultIfEmpty(data?.query8),
          });

          // Establecer la hora de la última actualización
          const now = new Date();
          const formattedTime = now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
          setLastUpdated(formattedTime);
        } else {
          handleAlert("fail", result.message || "Error al realizar la consulta");
        }
      } catch (error) {
        handleAlert("fail", error.message || "Error al realizar la consulta");
      }
    };

    // Llamar a la función para obtener datos al montar el componente
    fetchData();

    // Función de limpieza (cleanup function) que se ejecuta cuando el componente se desmonta
    // Establece isMounted a false para evitar actualizar el estado en un componente desmontado,
    // lo que podría causar errores como "Can't perform a React state update on an unmounted component"
    // y potenciales fugas de memoria (memory leaks)
    return () => {
      isMounted = false;
    };
  }, [canViewCharts]);

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  // Renderizar componente
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={typeAlert}
        message={message}
      ></MDAlert>
      {canViewCharts ? ( // Mostrar contenido solo si tiene permisos
        <>
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="badge"
                    title="Total de carnets"
                    count={statistics.query1}
                    date="Recién actualizado"
                    percentage={{
                      color: "success",
                      label: `Actualizado a las ${lastUpdated}`,
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="hail"
                    title="Total carnets estudiantes"
                    count={statistics.query2}
                    date="Recién actualizado"
                    percentage={{
                      color: "success",
                      label: `Actualizado a las ${lastUpdated}`,
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="local_library"
                    title="Total carnets visitantes"
                    date="Recién actualizado"
                    count={statistics.query3}
                    percentage={{
                      color: "success",
                      label: `Actualizado a las ${lastUpdated}`,
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="upload_file"
                    title="Cantidad total de tipos de fuentes"
                    count={statistics.query4}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: `Actualizado a las ${lastUpdated}`,
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
            <MDBox mt={4.5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <ReportsBarChart
                      color="info"
                      title={<span style={{ textTransform: "none" }}>Carnets creados</span>}
                      date={`Actualizado a las ${lastUpdated}`}
                      chart={statistics.query5}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <ReportsLineChart
                      color="success"
                      title={<span style={{ textTransform: "none" }}>Carnets por fuente</span>}
                      date={`Actualizado a las ${lastUpdated}`}
                      chart={statistics.query6}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <MDBox mb={3}>
                    <ReportsLineChart
                      color="dark"
                      title={<span style={{ textTransform: "none" }}>Carnets usados</span>}
                      date={`Actualizado a las ${lastUpdated}`}
                      chart={statistics.query7}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>

          <Footer />
        </>
      ) : (
        // Si no tiene permisos, mostrar un mensaje o dejar vacío
        <MDBox py={3} display="flex" flexDirection="column" alignItems="center" textAlign="center">
          <img
            src={imagenes}
            alt="Sin permisos"
            style={{
              maxWidth: "100%", // Se adapta a pantallas pequeñas
              height: "auto",
              opacity: 0.5, // Ajusta la opacidad (0.6 es un valor moderado)
              marginBottom: "16px",
            }}
          />
        </MDBox>
      )}
    </DashboardLayout>
  );
}

export default Bienvenida;
