import { Alert, AlertTitle, Button, IconButton, Snackbar } from "@mui/material";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

export default function MDAlert({ isAlert, setIsAlert, typeAlert, message }) {
  const [state, setState] = useState({
    vertical: "bottom",
    horizontal: "left",
  });
  const { vertical, horizontal } = state;
  let isAlertState = isAlert;

  //   const handleClick = (newState) => () => {
  //     setState({ ...newState, open: true });
  //   };

  const handleClose = () => {
    setIsAlert(false);
  };

  //   const handleClose = (event, reason) => {
  //     if (reason === "clickaway") {
  //       return;
  //     }
  //     setState({ ...state, open: false });
  //   };

  return (
    <>
      {typeAlert === "success" && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isAlert}
          onClose={handleClose}
          message="I love snacks"
          key={vertical + horizontal}
        >
          <Alert severity="success" onClose={handleClose}>
            <AlertTitle>La solicitud se hizo correctamente</AlertTitle>
            {message ? message : ""}
          </Alert>
        </Snackbar>
      )}
      {typeAlert === "fail" && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isAlert}
          onClose={handleClose}
          message="I love snacks"
          key={vertical + horizontal}
        >
          <Alert severity="error" onClose={handleClose}>
            <AlertTitle>Fallo al realizar la solicitud</AlertTitle>
            {message ? message : ""}
          </Alert>
        </Snackbar>
      )}
      {typeAlert === "warning" && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={isAlert}
          onClose={handleClose}
          message="I love snacks"
          key={vertical + horizontal}
        >
          <Alert severity="warning" onClose={handleClose}>
            <AlertTitle>Se resolvió la solicitud parcialmente</AlertTitle>
            {message ? message : ""}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
