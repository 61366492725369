import { WARNING_MESSAGES, SUCCESS_MESSAGES } from "constants/messages";
import { formatMessage } from "utils/formatMessageUtils";

/**
 * Maneja las alertas basadas en los resultados de la actualización de vigencia.
 *
 * @param {number} numInvalidCards - Número de carnets inválidos.
 * @param {number} numCardsNotFound - Número de carnets no encontrados.
 * @param {Function} handleAlert - Función para mostrar alertas.
 */
export const handleValidityAlerts = (numInvalidCards, numCardsNotFound, handleAlert) => {
  if (numInvalidCards > 0 && numCardsNotFound === 0) {
    handleAlert(
      "warning",
      formatMessage(WARNING_MESSAGES.INVALID_CARDS, { count: numInvalidCards })
    );
  } else if (numCardsNotFound > 0 && numInvalidCards === 0) {
    handleAlert(
      "warning",
      formatMessage(WARNING_MESSAGES.NOT_FOUND_CARDS, { count: numCardsNotFound })
    );
  } else if (numInvalidCards > 0 && numCardsNotFound > 0) {
    handleAlert(
      "warning",
      formatMessage(WARNING_MESSAGES.INVALID_AND_NOT_FOUND_CARDS, {
        count: numInvalidCards + numCardsNotFound,
      })
    );
  } else {
    handleAlert("success", SUCCESS_MESSAGES.VALIDITY_UPDATED);
  }
};
