/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Juan Esteban Atehortua Sanchez                             ║
║ juan.atehortua@iudigital.edu.co                            ║
╚════════════════════════════════════════════════════════════╝
*/

import { useEffect, useState, useRef, useCallback } from "react";
import {
  RoleCreateService,
  RoleListService,
  RoleUpdateService,
  RoleDeleteService,
  RoleByIDService,
  RolePermisionsListService,
} from "../services/RoleService";
import RoleList from "../modules/RoleList";
import { RoleCreate } from "../modules/RoleCreate";
import { RoleEdit } from "../modules/RoleEdit";
import { RoleDelete } from "../modules/RoleDelete";
import FilterController from "components/filter/controller/FilterController";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import { useNavigate } from "react-router-dom";
import usePermission from "hooks/usePermission";
import { use } from "react";

export default function RoleController() {
  // Common Controllers
  const [isDisabled, setIsDisabled] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [isFormModified, setIsFormModified] = useState(false);
  const [recoveredData, setRecoveredData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [newData, setNewData] = useState({});
  const [buildData, setBuildData] = useState({});
  const [loading, setLoading] = useState(false);
  const header = [{}];
  const filterControllerRef = useRef();
  const [isAlert, setIsAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const dataFilter = useState([
    {
      field: "NOMBRE",
      value: "name",
    },
    {
      field: "DESCRIPCIÓN",
      value: "description",
    },
  ]);

  const dataTable = "roles"; //Tabla indicada

  // Field Check

  const getFieldValueNew = (fieldName) => {
    return newData[fieldName];
  };

  const getFieldValue = (fieldName) => {
    return editedData[fieldName];
  };

  const handleFieldChangeNew = (fieldName, value) => {
    setNewData({
      ...newData,
      [fieldName]: value,
    });
  };

  const handleFieldChange = (fieldName, value) => {
    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
  };

  // ByID Controllers

  const requestRoleByID = async (idRole) => {
    try {
      const result = await RoleByIDService(idRole);
      console.log(result);
      if (result.status !== false) {
        const { payload } = result;
        if (!payload) {
          throw new Error("No se encontró el Rol solicitado");
        }
        return { payload };
      } else {
        handleAlert("fail", result.message);
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al obtener el registro");
    }
  };

  // Permit List Controllers

  const [handleRolePermitList, setRolePermitList] = useState();

  const requestRolePermitList = async () => {
    try {
      const result = await RolePermisionsListService();
      if (result && result.payload && result.status !== false) {
        const { payload } = result;
        const rolePermitsArray = Object.entries(payload);

        setRolePermitList(rolePermitsArray);
      } else {
        handleAlert("fail", result.message);
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al obtener los permisos");
    }
  };

  useEffect(() => {
    requestRolePermitList();
  }, []);

  // List Controllers

  const [handleRoleList, setRoleList] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [paginaSize, setPaginaSize] = useState(10);

  const requestRoleList = async (pageIndex) => {
    try {
      const result = await RoleListService(pageIndex);
      if (result.status !== false) {
        const { payload } = result;
        await setRoleList(payload.data);
        await setTotalPages(payload.last_page);
        await setTotalData(payload.total);
        await setEntriesStart(payload.from);
        await setEntriesEnd(payload.to);
      } else {
        handleAlert("fail", result.message);
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al obtener los registros");
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const pageIndexAndPageSize = async (pageSize) => {
    await setPaginaSize(pageSize);
    requestRoleList(currentPage === 1 ? currentPage : (currentPage - 1) * pageSize + 1);
  };

  useEffect(() => {
    requestRoleList(currentPage === 1 ? currentPage : (currentPage - 1) * paginaSize + 1);
  }, [currentPage]);

  // Create Controllers

  const [openCreate, setOpenCreate] = useState(false);

  const handleRoleCreate = (event) => {
    event.preventDefault();
    setIsDisabled(false);
    const data = {
      name: "",
      description: "",
      //permissions: "",
    };
    setDefaultData(data);
    setNewData(data);
    setOpenCreate(true);
  };

  const submitRoleCreate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const convertedObject = convertValuesToString(newData);
    const result = await RoleCreateService(newData);
    if (result.status !== false) {
      handleAlert("success", "Registro creado correctamente");
      callHandleClickSubmit();
      closeRoleCreate();
    } else {
      handleAlert("fail", result.message);
    }
    setLoading(false);
    //requestRoleList(1);
  };

  const closeRoleCreate = () => setOpenCreate(false);

  // Edit Controllers

  const [openEdit, setOpenEdit] = useState(false);

  const handleRoleEdit = async (event) => {
    try {
      event.preventDefault();
      setLoading(true);
      setIsDisabled(event.currentTarget.dataset.id === "view" ? true : false);
      const role = await requestRoleByID(event.currentTarget.dataset.id);
      if (!role || !role.payload || !role.payload.id) {
        // No navegamos a ningún lado si no hay un ID válido
        return; // Sale de la función sin hacer nada más
      }
      navigate(`/Roles/${role.payload.id}`);
    } catch (error) {
      handleAlert("fail", error.message || "Error al obtener el registro");
    } finally {
      setLoading(false);
    }
  };

  const submitRoleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const convertedObject = convertValuesToString(editedData);
    const result = RoleUpdateService(editedData.id, editedData);
    if (result.status !== false) {
      //requestRoleList(1);
      callHandleClickSubmit();
      closeRoleEdit();
      handleAlert("success", "Registro actualizado correctamente");
      // const { data } = result;
    } else {
      handleAlert("fail", result.message);
    }
  };

  const closeRoleEdit = () => setOpenEdit(false);

  // Delete Controllers

  const [openDelete, setOpenDelete] = useState(false);
  const [infoDelete, setInfoDelete] = useState({});

  const requestRoleDelete = async (id) => {
    try {
      const result = await RoleDeleteService(id);

      if (result.status !== false) {
        handleAlert("success", "Registro eliminado correctamente");
        // const { data } = result;
      } else {
        handleAlert("fail", result.message);
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al eliminar el registro");
    }
  };

  const handleRoleDelete = (event) => {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const data = {
      id: dataset.id,
      name: dataset.name,
      //permissions: dataset.permissions,
    };
    setInfoDelete(data);
    setOpenDelete(true);
  };

  const submitRoleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    const convertedObject = convertValuesToString(infoDelete.id);
    await requestRoleDelete(infoDelete.id, infoDelete.id);
    //requestRoleList(1);
    callHandleClickSubmit();
    closeRoleDelete();
  };

  const closeRoleDelete = () => setOpenDelete(false);

  // Test Keys

  useEffect(() => {
    const hasFormChanged = Object.keys(editedData).some(
      (fieldName) => editedData[fieldName] !== recoveredData[fieldName]
    );

    const isAnyFieldEmpty = Object.values(editedData).some((value) => value === "");

    setIsFormModified(!isAnyFieldEmpty && hasFormChanged);
  }, [editedData]);

  useEffect(() => {
    const requiredFields = ["name", "description"];
    const someFieldIsEmpty = requiredFields.some((fieldName) => !newData[fieldName]);
    setIsFormModified(someFieldIsEmpty);
  }, [newData]);

  // Stringify

  function convertValuesToString(objeto) {
    const convertedObject = {};

    for (const clave in objeto) {
      if (objeto.hasOwnProperty(clave)) {
        convertedObject[clave] = String(objeto[clave]);
      }
    }

    return convertedObject;
  }

  // Función para llamar a handleClickSubmit desde el componente padre
  const callHandleClickSubmit = () => {
    if (filterControllerRef.current) {
      filterControllerRef.current.handleClickSubmit();
    }
  };

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  return (
    <>
      <Spinner loading={loading}></Spinner>
      {usePermission("Role.byFilter") && (
        <FilterController
          dataTable={dataTable}
          data={handleRoleList}
          dataFilter={dataFilter}
          setListData={setRoleList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setTotalPages={setTotalPages}
          requestData={requestRoleList}
          // setError={setError}
          setTotalData={setTotalData}
          setEntriesEnd={setEntriesEnd}
          setEntriesStart={setEntriesStart}
          setLoading={setLoading}
          ref={filterControllerRef}
        ></FilterController>
      )}
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={typeAlert}
        message={message}
      ></MDAlert>
      <RoleList
        handleRoleList={handleRoleList}
        handleRoleCreate={handleRoleCreate}
        handleRoleEdit={handleRoleEdit}
        handleRoleDelete={handleRoleDelete}
        pageIndexAndPageSize={pageIndexAndPageSize}
        entriesStart={entriesStart}
        entriesEnd={entriesEnd}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />

      <RoleCreate
        open={openCreate}
        handleClose={closeRoleCreate}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValueNew}
        handleFieldChange={handleFieldChangeNew}
        handleSubmit={submitRoleCreate}
        handleRolePermitList={handleRolePermitList}
      />

      <RoleEdit
        open={openEdit}
        handleClose={closeRoleEdit}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValue}
        handleFieldChange={handleFieldChange}
        handleSubmit={submitRoleEdit}
        handleRolePermitList={handleRolePermitList}
      />

      <RoleDelete
        openDelete={openDelete}
        closeDelete={closeRoleDelete}
        infoDelete={infoDelete}
        submitDelete={submitRoleDelete}
      />
    </>
  );
}
