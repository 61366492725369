/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import React from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { DataSourceFileController } from "./controllers/DataSourceFileController";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import CarnetsPerFileController from "layouts/carnets_per_file/controllers/CarnetsPerFileController";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HistoryIcon from "@mui/icons-material/History";

export default function SourcesFile() {
  const location = useLocation();
  const [nameSource, setNameSource] = useState("");
  const [valueTab, setValueTab] = useState(0); // Estado para controlar las pestañas

  // Manejar cambio de pestañas
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  useEffect(() => {
    // Recuperar datos de sessionStorage
    const storedNameSource = sessionStorage.getItem("nameSource");
    if (storedNameSource) setNameSource(storedNameSource);
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h3" color="white">
                  Listado de archivos de la fuente: {nameSource && nameSource}
                </MDTypography>
              </MDBox>

              {/* Pestañas */}
              <Box
                sx={{
                  width: { xs: "92%", sm: "35%" }, // Ancho completo en pantallas pequeñas, 35% en pantallas grandes
                  mt: 3,
                  position: "relative",
                  left: 15,
                  right: 1,
                }}
              >
                <Tabs
                  value={valueTab}
                  onChange={handleChangeTab}
                  aria-label="simple tabs example"
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" }, // Cambiar dirección en pantallas pequeñas
                    "& .MuiTab-root": {
                      textTransform: "none",
                      borderBottom: "none", // Eliminar borde inferior
                      borderRadius: "4px 4px 0 0", // Bordes redondeados en la parte superior
                      marginRight: { sm: "4px" }, // Espacio entre pestañas en pantallas grandes
                      marginBottom: { xs: "4px", sm: "0" }, // Espacio entre pestañas en pantallas pequeñas
                      backgroundColor: "#f0f0f0", // Fondo gris claro para pestañas no seleccionadas
                      padding: "5px 4px", // Ajustar padding
                      flex: 1, // Ocupar todo el espacio disponible
                      "&:hover": {
                        backgroundColor: "#e0e0e0", // Fondo gris más oscuro al pasar el cursor
                      },
                      "&.Mui-selected": {
                        border: "2px solid #3e98ef", // Borde azul
                        backgroundColor: "#ffffff", // Fondo blanco para pestañas seleccionadas
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Sombra para pestañas seleccionadas
                        borderBottom: "none", // Eliminar borde inferior de la pestaña seleccionada
                      },
                      minWidth: { sm: 200 }, // Ancho mínimo para cada pestaña en pantallas grandes
                      "@media (max-width: 600px)": {
                        minWidth: "100%", // Ancho completo para pantallas más pequeñas
                      },
                    },
                    "& .MuiTabs-scrollButtons": {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                  }}
                >
                  <Tab
                    icon={<ListAltIcon fontSize="small" />}
                    value={0}
                    label="Listado de carnets"
                    wrapped
                  />
                  <Tab
                    icon={<HistoryIcon fontSize="small" />}
                    value={1}
                    label="Historial de fuentes de datos"
                  />
                </Tabs>
              </Box>
              <Box
                sx={{
                  position: "relative",
                  width: { xs: "89%", sm: "97%" }, // 94% en pantallas pequeñas, 97% en pantallas grandes
                  bottom: 0,
                  left: 19,
                  right: 1,
                  height: "2px",
                  backgroundColor: "#3e98ef", // Color de la línea adicional
                  top: "-4px",
                }}
              />
              {valueTab === 0 && (
                <MDBox pt={3}>
                  <CarnetsPerFileController />
                </MDBox>
              )}
              {valueTab === 1 && (
                <MDBox pt={3}>
                  <DataSourceFileController />
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
