/**
=========================================================
Archivo para el login con Google
=========================================================
*
* La configuración se debe realizar en https://console.cloud.google.com/
* Usar el email: ellyn.vasquez@iudigital.edu.co
*/

import React, { useState, useContext, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { GoogleLogin } from "@react-oauth/google";
import { Navigate, useNavigate } from "react-router-dom";
import Icon from "@mui/material/Icon";
import MDAlert from "components/MDAlert";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useMaterialUIController } from "../../../context/index";
//import { useAuth } from "../../../context/index";

const google = () => {
  const [controller, dispatch] = useMaterialUIController();
  const Navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  //const { token, login, logout } = useAuth();

  const handleAlertClick = (dataResponse) => {
    setMessage(dataResponse.message);
    setShowAlert(true);
  };

  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleDialogClose = () => {
    closeAlert();
  };

  useEffect(() => {
    if (controller.user_id !== "") {
      Navigate("/Bienvenida");
    }
  }, [controller.user_id]);

  return (
    <div>
      <GoogleLogin
        onSuccess={async (credentialResponse) => {
          const googleApiUrl =
            "https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=" +
            credentialResponse.credential;

          fetch(googleApiUrl)
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error("Error en la solicitud a la API de Google");
              }
            })
            .then(async (userData) => {
              const email = userData.email;

              const url = process.env.REACT_APP_API_URL;
              try {
                const response = await fetch(
                  process.env.REACT_APP_API_URL + "/users/login?email=" + email
                );
                if (response.status === 429) {
                  // Manejar el error de demasiadas peticiones
                  // console.error('Error 429: Too Many Requests');
                  // Puedes lanzar un error o manejarlo de otra manera según tus necesidades
                  // throw new Error('Too Many Requests');
                  const message =
                    "Se ha excedido el límite de solicitudes al servidor. Por favor, inténtelo de nuevo más tarde.";
                  const status = false;
                  throw new Error(message);
                }
                const dataResponse = await response.json();

                if (dataResponse.success === true) {
                  await dispatch({ type: "UPDATE_USER_ID", payload: dataResponse.payload.user.id });
                  localStorage.setItem("user_id", dataResponse.payload.user.id);
                  localStorage.setItem("token", dataResponse.payload.token);
                  localStorage.setItem("roleId", dataResponse.payload.roleId);
                  localStorage.setItem("permissions", dataResponse.payload.permissions);
                  //const {
                  //payload: { token },
                  //} = dataResponse;
                  //login(token);
                  //Navigate("/bienvenida");
                } else {
                  // Usuario no autorizado, mostrar la alerta
                  handleAlertClick(dataResponse);
                }
              } catch (error) {
                handleAlertClick({ message: error.message || "Error al realizar la solicitud" });
              }
            })
            .catch((error) => {
              handleAlertClick({ message: error.mmesage || "Error al realizar la solicitud" });
            });
        }}
        onError={() => {}}
      />

      <Dialog open={showAlert} onClose={handleDialogClose}>
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
            backgroundColor: "#007BFF",
            color: "#FFF",
          }}
        >
          {"¡¡No estás autorizado para ingresar!!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              marginTop: "8px",
              backgroundColor: "#FFF",
              color: "#000",
            }}
          >
            <Icon fontSize="small"></Icon>&nbsp;{" "}
            {message ||
              "Por favor verifica tu dirección de correo electrónico que sea con el correo de la IU Digital"}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            onClick={handleDialogClose}
            color="primary"
            style={{ backgroundColor: "#007BFF", color: "#FFF" }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default google;
