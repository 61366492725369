/**
 * Reemplaza los marcadores de posición en una plantilla de texto con valores proporcionados.
 *
 * @param {string} template - La plantilla de texto que contiene marcadores de posición en el formato `{key}`.
 * @param {Object} values - Un objeto que contiene las claves y valores para reemplazar en la plantilla.
 *                           Las claves deben coincidir con los nombres de los marcadores de posición en la plantilla.
 * @returns {string} - La cadena de texto resultante con los marcadores de posición reemplazados por sus valores correspondientes.
 *
 * @example
 * const template = "Hola, {name}. Tienes {count} mensajes.";
 * const values = { name: "Juan", count: 5 };
 * const result = formatMessage(template, values);
 * console.log(result); // "Hola, Juan. Tienes 5 mensajes."
 *
 * @example
 * const template = "El archivo {file} no se pudo encontrar.";
 * const values = { file: "documento.pdf" };
 * const result = formatMessage(template, values);
 * console.log(result); // "El archivo documento.pdf no se pudo encontrar."
 */
export const formatMessage = (template, values) => {
  return template.replace(/{(\w+)}/g, (_, key) => values[key] || "");
};
