/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Victor Manuel Calle Londoño                                ║
║ victor.calle@est.iudigital.edu.co                          ║
╚════════════════════════════════════════════════════════════╝
*/
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Typography,
  TextField,
  styled,
  Button,
  Card,
  LinearProgress,
  CircularProgress,
  InputLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from "@mui/material";
import colors from "assets/theme/base/colors";
import closeImg from "assets/images/close_X.svg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";
import MDAlert from "components/MDAlert";
import React, { useState } from "react";
import DataTable from "examples/Tables/DataTable";
import MDProgress from "components/MDProgress";
import Spinner from "components/MDSpinner";

export function SourceFileNew({
  open,
  handleClose,
  isFormModified,
  isDisabled,
  getFieldValue,
  sourceTypes,
  carnetTypes,
  handleFieldChange,
  handleSubmit,
  handleEstablishConnection,
  setColumTableNative,
  columTableNative,
  columData,
  statusConexion,
  tableRows,
  loading,
  dataSource,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "1100px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
    maxHeight: "95vh",
  };

  const modalContentStyle = {
    padding: "20px",
    maxHeight: "80%", // Establece la altura máxima para el contenido del modal
    overflowY: "auto", // Agregar scroll vertical al contenido del modal
  };

  const content = {
    margin: "0",
    padding: "0",
    display: "grid",
    align: "center",
    width: "100%",
    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
    gap: "15px",
  };
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const tableColumns = [
    { Header: "BD Nativa", accessor: "staticValue" },
    { Header: "BD Entrante", accessor: "select" },
    // Tus otras columnas aquí...
  ];

  return (
    <>
      {/* <Spinner loading={loading}></Spinner> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={style}>
          <MDBox
            mt={-7}
            color="white"
            bgColor="info"
            variant="gradient"
            borderRadius="lg"
            p={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Typography id="modal-modal-title" component="h2">
              Nuevo archivo de la fuente de datos: {dataSource.name ? dataSource.name : ""}
            </Typography>
            <Icon
              sx={{
                fontSize: "2em",
                stroke: "currentColor",
                strokeWidth: "2px",
                cursor: "pointer",
                marginLeft: "auto",
              }}
              onClick={handleClose}
            >
              close
            </Icon>
          </MDBox>
          <Divider variant="middle" />
          <div style={{ maxHeight: "80%", overflowY: "auto" }}>
            <form onSubmit={handleSubmit}>
              <MDBox
                sx={content}
                style={{
                  justifyContent: "space-around",
                }}
              >
                <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Tipo de fuente
                  </Typography>
                  <FormControl sx={{ minWidth: 80 }}>
                    <Select
                      labelId="labelId-select-tipo-de-fuente"
                      id="select-tipo-de-fuente"
                      value={dataSource.type}
                      onChange={(e) => handleFieldChange("name", e.target.value)}
                      style={{ fontSize: "15px", padding: "10px" }}
                      disabled={true}
                      displayEmpty
                    >
                      <MenuItem value={""}>Seleccione...</MenuItem>
                      {sourceTypes
                        ? sourceTypes.map((source) => {
                            return (
                              <MenuItem key={source.id} value={source.name}>
                                {source.name}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Select>
                  </FormControl>
                </Box>
                {dataSource.type === "CSV" && (
                  <Box sx={{ marginRight: "20px" }}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Fuente: Archivo csv
                    </Typography>
                    <>
                      <span>
                        <a
                          href="/assets/Plantilla CSV.csv"
                          download="Plantilla CSV.csv"
                          target="_self"
                          title="Descarga la plantilla de ejemplo"
                          style={{ fontSize: "1.1rem" }}
                        >
                          Descargar plantilla
                        </a>
                      </span>

                      <MDInput
                        disabled={isDisabled}
                        style={{ marginTop: "5px" }}
                        label=""
                        type="file"
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          handleFieldChange("source", selectedFile);
                        }}
                      ></MDInput>
                    </>
                  </Box>
                )}
                {dataSource.type === "JSON" && (
                  <Box sx={{ marginRight: "20px" }}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Fuente: Archivo json
                    </Typography>
                    <>
                      <span>
                        <a
                          href="/assets/Plantilla JSON.json"
                          download="Plantilla JSON.json"
                          target="_self"
                          title="Descarga la plantilla de ejemplo"
                          style={{ fontSize: "1.1rem" }}
                        >
                          Descargar plantilla
                        </a>
                      </span>

                      <MDInput
                        disabled={isDisabled}
                        style={{ marginTop: "5px" }}
                        label=""
                        type="file"
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          handleFieldChange("source", selectedFile);
                        }}
                      ></MDInput>
                    </>
                  </Box>
                )}
                {dataSource.type === "EXCEL" && (
                  <Box sx={{ marginRight: "20px" }}>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      Fuente: Archivo excel
                    </Typography>
                    <>
                      <span>
                        <a
                          href="/assets/Plantilla Excel.xlsx"
                          download="Plantilla Excel.xlsx"
                          target="_self"
                          title="Descarga la plantilla de ejemplo"
                          style={{ fontSize: "1.1rem" }}
                        >
                          Descargar plantilla
                        </a>
                      </span>

                      <MDInput
                        disabled={isDisabled}
                        style={{ marginTop: "5px" }}
                        label=""
                        type="file"
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          handleFieldChange("source", selectedFile);
                        }}
                      ></MDInput>
                    </>
                  </Box>
                )}
                {getFieldValue("name") === "MySQL" && (
                  <>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Url de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Url de la base de datos"
                          value={getFieldValue("db_host")}
                          onChange={(e) => handleFieldChange("db_host", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Puerto de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("port")}
                          onChange={(e) => handleFieldChange("port", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de usuario
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("db_username")}
                          onChange={(e) => handleFieldChange("db_username", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Contraseña
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="password"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("db_password")}
                          onChange={(e) => handleFieldChange("db_password", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("nameDB")}
                          onChange={(e) => handleFieldChange("nameDB", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de la tabla
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("nameTable")}
                          onChange={(e) => handleFieldChange("nameTable", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Establecer conexión
                      </Typography>
                      <>
                        <MDButton onClick={handleEstablishConnection} color="info">
                          Aplicar
                        </MDButton>
                      </>
                    </Box>
                    {statusConexion && <></>}
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Relacione los campos de nuestra base de datos con la elegida:
                      </Typography>
                      <DataTable
                        sx={{ border: "1px solid black" }}
                        table={{
                          columns: tableColumns,
                          rows: tableRows,
                        }}
                        canSearch={false}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                      />
                    </Box>
                  </>
                )}
                {getFieldValue("name") === "PostgreSQL" && (
                  <>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Url de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Url de la base de datos"
                          value={getFieldValue("db_host")}
                          onChange={(e) => handleFieldChange("db_host", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Puerto de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("port")}
                          onChange={(e) => handleFieldChange("port", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de usuario
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("db_username")}
                          onChange={(e) => handleFieldChange("db_username", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Contraseña
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="password"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("db_password")}
                          onChange={(e) => handleFieldChange("db_password", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de la base de datos
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("nameDB")}
                          onChange={(e) => handleFieldChange("nameDB", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Nombre de la tabla
                      </Typography>
                      <>
                        <MDInput
                          disabled={isDisabled}
                          style={{ marginTop: "5px" }}
                          label=""
                          type="text"
                          placeholder="Nombre de la base de datos"
                          value={getFieldValue("nameTable")}
                          onChange={(e) => handleFieldChange("nameTable", e.target.value)}
                        ></MDInput>
                      </>
                    </Box>
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Establecer conexión
                      </Typography>
                      <>
                        <MDButton onClick={handleEstablishConnection} color="info">
                          Aplicar
                        </MDButton>
                      </>
                    </Box>
                    {statusConexion && <></>}
                    <Box sx={{ marginRight: "20px" }}>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Relacione los campos de nuestra base de datos con la elegida:
                      </Typography>
                      <DataTable
                        sx={{ border: "1px solid black" }}
                        table={{
                          columns: tableColumns,
                          rows: tableRows,
                        }}
                        canSearch={false}
                        isSorted={false}
                        entriesPerPage={false}
                        showTotalEntries={false}
                      />
                    </Box>
                  </>
                )}
                <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Tipo de carnet
                  </Typography>
                  <FormControl sx={{ minWidth: 80 }}>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={getFieldValue("perfil")}
                      onChange={(e) => handleFieldChange("perfil", e.target.value)}
                      style={{ fontSize: "15px", padding: "10px" }}
                      disabled={isDisabled}
                      displayEmpty
                    >
                      <MenuItem value={""}>Seleccione...</MenuItem>
                      {carnetTypes
                        ? carnetTypes.map((carnet) => {
                            return (
                              <MenuItem key={carnet.id} value={carnet.name}>
                                {carnet.name}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Select>
                  </FormControl>
                </Box>
                {/* <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Fecha de habilitación
                  </Typography>
                  <MDInput
                    type="date"
                    style={{ marginTop: "5px" }}
                    label=""
                    value={getFieldValue("created")}
                    onChange={(e) => handleFieldChange("created", e.target.value)}
                  />
                </Box>
                <Box>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Fecha de vigencia
                  </Typography>
                  <MDInput
                    type="date"
                    style={{ marginTop: "5px" }}
                    label=""
                    value={getFieldValue("expires_in")}
                    onChange={(e) => handleFieldChange("expires_in", e.target.value)}
                  />
                </Box> */}
              </MDBox>
            </form>
          </div>
          <Divider variant="middle" />

          <MDBox
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
            m={-0.5}
            mb={-2}
          >
            <MDButton color="error" onClick={handleClose}>
              Cancelar
            </MDButton>
            <MDButton
              onClick={handleSubmit}
              type="submit"
              disabled={isDisabled || isFormModified}
              color="success"
            >
              Guardar
            </MDButton>
          </MDBox>
        </Card>
      </Modal>
    </>
  );
}

SourceFileNew.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isDisabled: PropTypes.bool,
  getFieldValue: PropTypes.func,
  handleFieldChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  isFormModified: PropTypes.bool,
};
